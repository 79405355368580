import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'block']

  connect() {
    this.toggle(this.checked)
  }

  checkboxTargetConnected(el) {
    this.checked = el.checked;
  }

  change(e) {
    this.checked = e.target.checked;
    this.toggle(this.checked)
  }

  toggle(state) {
    // if (this.hasblockTarget) {
      if (state) {
        this.blockTarget.classList.remove('hidden')
      } else {
        this.blockTarget.classList.add('hidden')
      }
    // }
  }
}
