import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import stripeForm from "src/nextgen/Payments/Form/stripeForm";

export default class extends Controller {
  static targets = ['spinner']

  connect() {
    const dataset = this.element.dataset;

    this.subscriptions = App.cable.subscriptions;

    this.stripeAccount = dataset.stripeAccount;
    this.stripe = this.stripeAccount ? Stripe(App.stripe._apiKey, { stripeAccount: this.stripeAccount }) : App.stripe;
    this.sendForm  = dataset.sendForm;
    this.offline = dataset.offline === "true" || false;
    this.stripeIntentStatus = dataset.stripeIntentStatus;
    this.clientSecretUrl = dataset.clientSecretUrl;

    this.modal = this.element.closest('[data-controller="neo_modal"]');

    if (this.offline) {
      this.spinnerTarget.style.display = '';
      document.body.dataset.paymentProcessing = 'true';

      setTimeout(() => {
        this.getClientSecret().then(modification => {
          this.subscribe(modification.id, () => {
            axios.post(`/orders/${modification.orderId}/checkout/payment/confirm?modification_id=${modification.id}`).then((result) => {
              // do nothing
              // and wait
            }).catch(() => {
              this.unsubscribe();
              $(this.modal).modal('hide');
              Helper.flash_message('error', 'Something went wrong');
            });
          })
        })
      }, 200);
    }

    // specific actions
    if (this.stripeIntentStatus === 'requires_action') {
      this.showSpinner();
      this.processStripeCard({
        id: dataset.modificationId,
        clientSecret: dataset.clientSecret
      });
    }

    const { enableInputs } = stripeForm({
      stripe: this.stripe,
      onSubmit: this.onSubmitForm,
      onEnabledInputs: this.hideSpinner,
      onDisabledInputs: this.showSpinner
    })

    this.enableInputs = enableInputs;
  }

  onSubmitForm = (cardNumber, additionalData) => {
    const submitEvent = new CustomEvent('neo_modal:submit');
    this.modal.dispatchEvent(submitEvent);

    this.showSpinner();

    this.getClientSecret().then(modification => {
      this.processCard(modification, cardNumber, additionalData)
    }).catch((e) => {
      this.hideSpinner();
      this.enableInputs();

      if (e.response.status === 422) {
        const failedEvent = new CustomEvent('neo_modal:failed', { detail: { data: e.response.data } });
        this.modal.dispatchEvent(failedEvent);
      } else {
        this.handleFailed('Something went wrong')
      }
    })
  }

  processCard(modification, cardNumber, additionalData) {
    const attrs = {
      payment_method: {
        card: cardNumber,
        billing_details: {
          name: additionalData.name
        }
      }
    }

    this.processStripeCard(modification, attrs);
  }

  processStripeCard(modification, attrs) {
    const { id, clientSecret } = modification;

    const confirmFn = this.confirmMethod(clientSecret);

    if (confirmFn === null) {
      this.handleFailed('Unexpected result')
      return;
    }

    this.subscribe(id, () => {
      confirmFn(clientSecret, attrs).then((result) => {
        if (result.error) {
          this.unsubscribe()
          this.hideSpinner()
          this.handleFailed({ message: result.error.message })
        }
      });
    })
  }

  showSpinner = ()=> {
    document.body.dataset.paymentProcessing = 'true';
    if (this.offline) return;
    this.spinnerTarget.style.display = '';
  }

  hideSpinner = () => {
    if (this.offline) return;
    delete document.body.dataset.paymentProcessing;
    this.spinnerTarget.style.display = 'none';
  }

  getClientSecret() {
    const formData = this.sendForm ? new FormData($(`#${this.sendForm}`)[0]) : {};

    return axios
      .post(this.clientSecretUrl, formData, { headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then(response => {
        if (response.data.reload) {
          window.location.reload();
        } else {
          return response.data.modification
        }
      })
  }

  handleSuccess(data) {
    const successEvent = new CustomEvent('neo_modal:payment_success', { detail: { data: data } });
    this.modal.dispatchEvent(successEvent);
  }

  handleFailed(data) {
    const successEvent = new CustomEvent('neo_modal:payment_failed', { detail: { data: data } });
    this.modal.dispatchEvent(successEvent);

    if (data.message) Helper.flash_message('error', data.message)
    this.hideSpinner();
    this.enableInputs();
  }

  subscribe(modificationId, onConnected) {
    this.subscription = this.subscriptions.create(
      { channel: "ModificationChannel", id: modificationId },
      {
        connected: onConnected,
        received: (data) => {
          if (data.version !== 2) return;

          switch (data.status) {
            case 'succeeded':
              this.unsubscribe();
              this.handleSuccess(data);
              break;
            case 'failed':
              this.unsubscribe();
              this.handleFailed(data);
              break;
            default:
              console.log(`Unknown status: ${data.status}`)
              break;
          }
        }
      }
    )

    return this.subscription;
  }

  unsubscribe() {
    this.subscription.unsubscribe()
  }

  confirmMethod(clientSecret) {
    let confirmFn = null;

    if (/^seti_/.test(clientSecret)) confirmFn = this.stripe.confirmCardSetup;
    if (/^pi_/.test(clientSecret)) confirmFn = this.stripe.confirmCardPayment;

    if (confirmFn === null || confirmFn === undefined) return null;

    return confirmFn;
  }
}

